export default class MediacopyrightService {
    constructor() {
        if ($('.mediacopyright').length > 0) {
            import(
                '../components/mediacopyright.component' /* webpackChunkName: "scripts/mediacopyright.component" */
            ).then(({ default: MediacopyrightComponent }) => {
                new MediacopyrightComponent()
            })
        }
    }
}
